<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">快看，历思“智慧禁毒”黑科技，上电视台啦~</div>
      <p class="time">2020.06.28</p>
      <p class="space_content" >
        6.26国际禁毒日即将到来，历思一则“智慧禁毒”黑科技的新闻，登上福建省电视台新闻频道特别节目——《禁毒之战2020》，提前给大家进行了“热身”。
      </p>


      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/30-1.png')">
      </div>

      <p class="space_content" >
        如今的“智慧禁毒”已渗透到禁毒工作的方方面面，“科技助力”是当前禁毒工作最核心的力量。在实际禁毒工作中，基于智慧禁毒的司法鉴定，已成为禁毒工作强大的科技助力。
      </p>
      <p class="space_content" >
        下面让我们一起来看看，历思“黑科技”加持“智慧禁毒”，是如何显神威的：
      </p>

      <p class="time" style="font-weight: 500;color: black">
        点击观看完整视频：
      </p>
      <p class="time" style="font-weight: 500;color: black">
        <a style="text-align: left; word-break: break-all;
        word-wrap: break-word;
"
           href="https://v.qq.com/x/page/c3102l3f730.html">
          https://v.qq.com/x/page/c3102l3f730.html
        </a>
      </p>
      <p class="time" style="font-weight: 500;color: black">
        (“智慧禁毒”构筑新格局 高科技织起禁毒密网)
      </p>

    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/29'"><p class="ellipsis1">母亲节福利来了！珠宝玉石免费检测鉴定活动——3天！</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/31'"><p class="ellipsis1">庆贺！集美大学信息工程学院与历思“牵手”成功！</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
